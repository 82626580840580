import axios from "axios";
import base64 from 'react-native-base64';

export const axiosInstance = axios.create({
  baseURL: 'https://do.martianis.com/api/v2/',
  //timeout: 1000,
});

axiosInstance.interceptors.request.use(
  function(config) {
    const loginToken = localStorage.getItem('loginToken'); 
    config.headers["content-type"] = 'application/x-www-form-urlencoded';
    if (loginToken) {
      config.headers["Authorization"] = 'Basic '+ base64.encode(loginToken+':Mars');
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

