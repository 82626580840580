import React from "react";
// Importing the Context Provider & Home Component
import MyContextProvider from "./contexts/MyContext";
import Auth from "./layouts/Auth";

function App() {
  return (
    <MyContextProvider>
        <Auth/>
    </MyContextProvider>
  );
}

export default App;
