/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
/*IMAGES*/
import pattern_react from '../assets/img/pattern_react.png';
import ad_mi_plan from '../assets/img/ad-mi-plan.png';
import component_profile_card from '../assets/img/component-profile-card.png';
import component_btn from '../assets/img/component-btn.png';
import component_info_card from '../assets/img/component-info-card.png';
import component_info_2 from '../assets/img/component-info-2.png';
import component_menu from '../assets/img/component-menu.png';
import component_btn_pink from '../assets/img/component-btn-pink.png';
import login from '../assets/img/login.jpg';
import documentation from '../assets/img/documentation.png';
import profile from '../assets/img/profile.jpg';
import landing from '../assets/img/landing.jpg';

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
	   <MetaTags>
            <title>Drone Delivery Network by Martianis</title>
            <meta name="description" content="Martianis have designed revenue program for Aircraft Owners & play role as middle man or agency to manage all commecrial aspects such as Maintenance~" />
            <meta name="keywords" content="drone pilot jobs, drone pilot careers, drone pilot jobs in punjab" />
            <meta property="og:title" content="Drone Delivery Network" />
            <meta property="og:image" content="../assets/img/component-profile-card.png" />
      </MetaTags>
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 sm:pt-0">
              <h2 className="font-semibold text-4xl text-gray-700">
                Drone Delivery Network - 
              </h2>
              <p className="mt-4 text-lg leading-relaxed text-gray-600">
                <strong>DDN Platform For Pilots</strong> certainly is going to create lots of opportunities for new Pilots to fly Aircrafts within city and make fortune! UAV Pilot can fly wide range of Aircrafts from a Small Quadcoptor Drone to high command jumbo jet RPA~ 
              </p>
				<br/>
				<strong>For Stakeholders</strong> 
				<p className="mt-4 text-lg leading-relaxed text-gray-600"> Martianis have designed revenue program for Aircraft Owners & play role as middle man or agency to manage all commecrial aspects such as Maintenance, Pilots & other Port services~
				</p>
				<p className="mt-4 text-lg leading-relaxed text-gray-600"> Contact us if you are interested to invest with us~ We will send full detailed reimbursement plan~
				</p>
              <div className="mt-12">
                <a
                  href="https://do.martianis.com/web/register/register"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blue-500 active:bg-blue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                Join as Pilot
                </a>
                <a
                  href="https://do.martianis.com/web/register/register"
                  className="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gray-800 active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Register as Company 
                </a>
              </div>
            </div>
          </div>
        </div>

        <img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          src={pattern_react}
          alt="..."
        />
      </section>

      <section className="mt-48 md:mt-40 pb-40 relative bg-gray-200">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-600">
                <img
                  alt="..."
				  className="w-full align-middle rounded-t-lg"
                  src={ad_mi_plan}
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-blue-600 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-white">
                    Advance Mission Planner
                  </h4>
                  <p className="text-md font-light mt-2 text-white">
                    Putting together a cloud based platform has never been easier than before~
                    DDN by Martianis offers outstunning performance on UAV's. 
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-sitemap"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Command and Control
                      </h6>
                      <p className="mb-4 text-gray-600">
                        Cloud-based platform as well as a mobile operator software that can be used for simultaneous command and control functionalityas well as communications for line of sight (LOS) and beyond line of sight (BLOS) operations.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-plane"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Fleet Management
                      </h6>
                      <p className="mb-4 text-gray-600">
                       For companies that hold multiple aircrafts, the platform allows to keep profile of each aircraft with details such as Flight Checklists, Incident Reporting and Flight hours logging etc much more~
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-drafting-compass"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">Flight Planning</h6>
                      <p className="mb-4 text-gray-600">
                        Flight planning tools and data services can be utilized for planning a flight with way points, aerial view of flying space and information about airspace adding weather forecast in it~
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-file-alt"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Live Data Streaming
                      </h6>
                      <p className="mb-4 text-gray-600">
                        Late 2020's advance technologies proven with fully autonomous flight functions provides an additional real time integrated video streaming and sensor control for commercial drones given enhanced security.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto overflow-hidden pb-20">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-sitemap text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Cloud Hub for RPA Pilots~
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                Every flight that a Pilot like to accomplish, needs a flight plan with proper cordinates, air traffic 
				and much more in accordance in order to success flight mission~
              </p>
              <div className="block pb-6">
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Advance Mission Planner
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Autonomous Flight System
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  BVLOS Operations
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Ground Station for Drone/Aircrafts
                </span>
              </div>
              <a
                href="#"
                target="_blank"
                className="font-bold text-gray-800 hover:text-gray-600 ease-linear transition-all duration-150"
              >
                View All{" "}
                <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
              </a>
            </div>

            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
              <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                <img
                  alt="drone delivery network"
                  src={component_btn}
                  className="w-full align-middle rounded absolute shadow-lg max-w-100-px z-3 left-145-px -top-29-px"
                />
                <img
                  alt="drone delivery network"
                  src={component_profile_card}
                  className="w-full align-middle rounded-lg absolute shadow-lg -top-160-px left-260-px max-w-210-px"
                />
                <img
                  alt="drone delivery network"
                  src={component_info_card}
                  className="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2"
                />
                <img
                  alt="drone delivery network"
                  src={component_info_2}
                  className="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
                />
                <img
                  alt="drone delivery network"
                  src={component_menu}
                  className="w-full align-middle rounded absolute shadow-lg max-w-580-px -left-20-px top-210-px"
                />
                <img
                  alt="drone delivery network"
                  src={component_btn_pink}
                  className="w-full align-middle rounded absolute shadow-xl max-w-120-px left-195-px top-95-px"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center pt-32">
            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
              <div className="justify-center flex flex-wrap relative">
                <div className="my-4 w-full lg:w-6/12 px-4">
                  <a
                    href="#"
                    target="_blank"
                  >
                    <div className="bg-red-600 shadow-lg rounded-lg text-center p-8">
                       <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-plane"></i>
                      </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        NOTAM-UAVSO
                      </p>
                    </div>
                  </a>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <div className="bg-blue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-plane"></i>
                      </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Autonomous Mission
                      </p>
                    </div>
                  </a>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <div className="bg-gray-800 shadow-lg rounded-lg text-center p-8 mt-8">
                      <div className="bg-blue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-plane"></i>
                      </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        DronePort
                      </p>
                    </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Future Transportation
                      </p>
                    </div>
                  </a>
                </div>
                <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                  <a
                    href="#"
                    target="_blank"
                  >
                    <div className="bg-orange-500 shadow-lg rounded-lg text-center p-8">
                      <div className="bg-blue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-plane"></i>
                      </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Cloud HUB
                      </p>
                    </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Multi-Platform
                      </p>
                    </div>
                  </a>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <div className="bg-grey-500 shadow-lg rounded-lg text-center p-8 mt-8">
                       <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-rupee-sign"></i>
                      </div>
                      <p className="text-lg text-green mt-4 font-semibold">
                        Made in India
                      </p>
                    </div>
                  </a>
				  <a
                    href="#"
                    target="_blank"
                  >
                    <div className="bg-green-500 shadow-lg rounded-lg text-center p-8">
                       <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-plane"></i>
                      </div>
                      <p className="text-lg text-black mt-4 font-semibold">
                        martianis
                      </p>
                    </div>
                  </a>
                </div>
				
              </div>
            </div>

            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-plane text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Airport/DronePort Services
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                World class service, exceptional experience with quality leaders grounded from nation, MARS DronePort will be offering wide variety of services to UAV Oprators and Pilots in Jalandhar City~
              </p>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                At Martianis, Engineers have created phenomenal machines that could fly fully autonomous with our advance cloud based mission controller~
              </p>
              <div className="block pb-6">
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  LiPO Battery Recharging Station
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Return to Home Configuration
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Flight Planning
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Flight Routes
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Navigation Guidance
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Weather Notifications
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Mission Planner
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Safe Flight tips
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  VFR Charts
                </span>
              </div>
              <a
                href="https://www.martianis.com"
                target="_blank"
                className="font-bold text-gray-800 hover:text-gray-600 ease-linear transition-all duration-150"
              >
                View all{" "}
                <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 pb-32 pt-48">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-plane text-xl"></i>
                </div>
                <h3 className="text-3xl font-semibold">
                    A Full Suite of Services
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                  At Martianis, our aim is establish Made in India brand since we are developing from scrath here in India.
                </p>
                    <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 mr-3">
                          <i className="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Aircraft Management
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 mr-3">
                          <i className="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Aircraft Maintenance
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 mr-3">
                          <i className="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Dedicated Account Managers to Aircraft Owners
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 mr-3">
                          <i className="fa fa-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Air Traveller’s Cargo Transport Programs
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={documentation}
              />
            </div>
          </div>
        </div>

        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <h2 className="font-semibold text-4xl">Categories of Drone/Aircraft Fleet </h2>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-600">
              At Martianis, we have devided aircraft category for delivery services starting from 1 Kg Payload~
            </p>
          </div>
        </div>
      </section>

      <section className="block relative z-1 bg-gray-700">
        <div className="container mx-auto">
          <div className="justify-center flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4  -mt-24">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    GunaKaari
                  </h5>
                   <a
                  href="https://do.martianis.com/web/site/fleet"
                  className="text-gray-400"
					>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="drone delivery network gunakaari"
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={login}
                      />
                    </div>
                  </a>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    BhagyaShaali
                  </h5>
				  <a
                  href="https://do.martianis.com/web/site/fleet"
                  className="text-gray-400"
					>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="drone delivery network bhagyashaali"
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={profile}
                      />
                    </div>
                  </a>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    ShaktiShaali
                  </h5>
                   <a
                  href="https://do.martianis.com/web/site/fleet"
                  className="text-gray-400"
					>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="drone delivery network shaktishaali"
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={landing}
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-gray-700 overflow-hidden">
        <div className="container mx-auto pb-64">
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
              <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center 	w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-road text-x3"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                Established in 2020
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-500">
                Since{" "}
                <a
                  href="https://martianis.com"
                  className="text-gray-400"
                  target="_blank"
                >
                  Martianis Flight Club
                </a>{" "}
                 is established in late 2020 by young entrepreneurs from the heart of city Jalandhar located in North Indian state called Punjab. The first aviation club in Jalandhar offering various pilot training programs with practical training hands on UAVs on BVLOS operations~</p>
				<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-500"> 
				 Team also have developed own platform with mission planner advance features for fully autonomous operations that will be available worldwide. 
              </p>
            </div>
            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative">
              <i className="fa fa-rocket text-gray-800 absolute -top-150-px -right-100 left-auto opacity-80 text-55"></i>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-16 bg-gray-300 relative pt-32">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
            <div className="w-full text-left lg:w-8/12">
              <p className="text-4xl text-center">
                <span role="img" aria-label="love">
                  
                </span>
              </p>
              <h3 className="font-semibold text-3xl">
                Investor Relations
              </h3>
              <p className="text-gray-600 text-lg leading-relaxed mt-4 mb-4">
               We have designed business plan and prototype where Indian Citizens and some foreign countries* citizens can invest not only for money but by Investing with Martianis, we are creating opportunities for youth to play essential roles in coming up years. <br/> <br/> <strong>Martianis Drone-port </strong>will also be known as Airport that will not only serve a city, it will be expanding horizons towards other cities of India~
              </p>
              <div className="sm:block flex flex-col mt-10">
                <a
                  href="#"
                  target="_blank"
                  className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gray-800 active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg"
                >
                  <i className="fa fa-plane text-lg mr-1"></i>
                  <span> Contact</span>
                </a>
              </div>
              <div className="text-center mt-16"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
