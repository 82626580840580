import React from "react";

// components
import { axiosInstance } from '../../axiosInstance';
// components

import TableDropdown from "components/Dropdowns/TableDropdown.js";

class AircraftManager extends React.Component {
	constructor(props){
		super(props);

		this.state = {
		  aircraftdata: []
		};
	  };
		
	UNSAFE_componentWillMount() {
		 let currentComponent = this;
		axiosInstance.get('aircraft/aircrafts')
		  .then(function (response) {
				console.log(response.data);

				currentComponent.setState({
					aircraftdata: response.data.aircrafts
				  });
			  })
			.catch(function (error) {
				console.log(error);
		  });
	  }

	
   render() {
	   const color = 'light';
	   const flts = this.state.aircraftdata;
	   let renderItems = flts.map(function(item, i) {
		  return (<tr>
						<th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left flex items-center">
						  <span
							className={
							  "ml-3 font-bold " +
							  +(color === "light" ? "text-gray-700" : "text-white")
							}
						  >
							{item.id}
						  </span>
						</th>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  {item.name}
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  {item.price}
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  {item.status}
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  <div className="flex">
							{item.vehicle_passengers}
						  </div>
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  <div className="flex items-center">
							{item.vehicle_luggages}
						  </div>
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  <div className="flex items-center">
							<img
							  src={"https://ddn.martianis.com/web/uploads/vehicle/"+item.image}
							  alt="..."
							  className="w-25 h-25 rounded-full border-2 border-gray-100 shadow -ml-4"
							></img>
						  </div>
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right">
						  <TableDropdown />
						</td>
					  </tr>)
		});
	  return (
		<>
		  <div className="flex flex-wrap mt-4">
			<div className="w-full mb-12 px-4">
			   <div
				className={
				  "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
				  (color === "light" ? "bg-white" : "bg-blue-900 text-white")
				}
			  >
				<div className="rounded-t mb-0 px-4 py-3 border-0">
				  <div className="flex flex-wrap items-center">
					<div className="relative w-full px-4 max-w-full flex-grow flex-1">
					  <h3
						className={
						  "font-semibold text-lg " +
						  (color === "light" ? "text-gray-800" : "text-white")
						}
					  >
						Aircrafts Manager
						
					  </h3>
					</div>
				  </div>
				</div>
				<div className="block w-full overflow-x-auto">
				  {/* Projects table */}
				  <table className="items-center w-full bg-transparent border-collapse">
					<thead>
					  <tr>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Aircraft ID
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Name
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Rent
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Status
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Carrying Capacity
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Distance Range
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Image
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						></th>
					  </tr>
					</thead>
					<tbody> 
					{renderItems}
					</tbody>
				  </table>
				</div>
			  </div>
			</div>
		  </div>
		</>
	  );
	}
}


export default AircraftManager;
//////vinis////////

