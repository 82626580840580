import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('loginToken');
     return tokenString
  };

  const [loginToken] = useState(getToken());

  /*const saveToken = userToken => {
    localStorage.setItem('loginToken', JSON.stringify(userToken));
    setToken(userToken.loginToken);
  };*/

  return {
    loginToken
  }
}
