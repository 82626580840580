import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import MissionMap from "views/admin/MissionMap.js";
import MissionPlanner from "views/admin/MissionPlanner.js";
import MissionPlans from "views/admin/MissionPlans.js";
import SafetyManuals from "views/admin/SafetyManuals.js";
import Flights from "views/admin/Flights.js";
import FlightPlan from "views/admin/FlightPlan.js";
import FlightManagement from "views/admin/FlightManagement.js";
import FlightLogs from "views/admin/FlightLogs.js";
import ChecklistManager from "views/admin/ChecklistManager.js";
import FlightInstruments from "views/admin/FlightInstruments.js";
import AircraftInstruments from "views/admin/AircraftInstruments.js";
import Aircrafts from "views/admin/Aircrafts.js";
import AircraftManager from "views/admin/AircraftManager.js";
import Settings from "views/admin/Settings.js";
import IncidentReports from "views/admin/IncidentReports.js";
import useToken from '../useToken';


export default function Admin() {
	
   const { loginToken } = useToken();
  
  if(!loginToken) {
    return (
		 <Switch>
				<Redirect to="/auth/login" />
		</Switch>
    );
  }
	
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/maps" exact component={Maps} />
            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/mission-map" exact component={MissionMap} />
            <Route path="/admin/mission-planner" exact component={MissionPlanner} />
            <Route path="/admin/mission-plans" exact component={MissionPlans} />
            <Route path="/admin/safety-manuals" exact component={SafetyManuals} />
            <Route path="/admin/flights" exact component={Flights} />
            <Route path="/admin/flight-plans" exact component={FlightPlan} />
            <Route path="/admin/flight-management" exact component={FlightManagement} />
            <Route path="/admin/flight-logs" exact component={FlightLogs} />
            <Route path="/admin/checklist-manager" exact component={ChecklistManager} />
            <Route path="/admin/flight-instruments" exact component={FlightInstruments} />
            <Route path="/admin/aircraft-instruments" exact component={AircraftInstruments} />
            <Route path="/admin/aircrafts" exact component={Aircrafts} />
            <Route path="/admin/aircrafts-manager" exact component={AircraftManager} />
            <Route path="/admin/incident-reports" exact component={IncidentReports} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
