import React from "react";
import { axiosInstance } from '../../axiosInstance';
// components

import TableDropdown from "components/Dropdowns/TableDropdown.js";

class FlightPlan extends React.Component {
	constructor(props){
		super(props);

		this.state = {
		  flightdata: []
		};
	  };
		
	UNSAFE_componentWillMount() {
		 let currentComponent = this;
		axiosInstance.get('ride/flightplan')
		  .then(function (response) {
				console.log(response.data);

				currentComponent.setState({
					flightdata: response.data.flights
				  });


			  })
			.catch(function (error) {
				console.log(error);
		  });
	  }
		
/*		
	let [responseData, setResponseData] = React.useState('');
  
	 // fetches data
    const fetchData = (e) => {
        e.preventDefault();
       axiosInstance.get('ride/flights')
        .then((response)=>{
            setResponseData(response.data)
            console.log(response)
        })
        .catch((error) => {
            console.log(error)
        })
    }; */
	
   render() {
	   const color = 'light';
	   const flts = this.state.flightdata;
	   let renderItems = flts.map(function(item, i) {
		  return (<tr>
						<th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left flex items-center">
						  <span
							className={
							  "ml-3 font-bold " +
							  +(color === "light" ? "text-gray-700" : "text-white")
							}
						  >
							{item.id}
						  </span>
						</th>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  {item.pickup_time}
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  {item.pickup_address}
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  {item.destination_address}
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  {item.approx_price}
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  {item.customer_name}
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  <div className="flex">
							{item.status}
						  </div>
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
						  <div className="flex items-center">
							{item.pickup_date}
						  </div>
						</td>
						<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right">
						  <TableDropdown />
						</td>
					  </tr>)
		});
	  return (
		<>
		  <div className="flex flex-wrap mt-4">
			<div className="w-full mb-12 px-4">
			   <div
				className={
				  "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
				  (color === "light" ? "bg-white" : "bg-blue-900 text-white")
				}
			  >
				<div className="rounded-t mb-0 px-4 py-3 border-0">
				  <div className="flex flex-wrap items-center">
					<div className="relative w-full px-4 max-w-full flex-grow flex-1">
					  <h3
						className={
						  "font-semibold text-lg " +
						  (color === "light" ? "text-gray-800" : "text-white")
						}
					  >
						My Flight Plans
					  </h3>
					</div>
				  </div>
				</div>
				<div className="block w-full overflow-x-auto">
				  {/* Projects table */}
				  <table className="items-center w-full bg-transparent border-collapse">
					<thead>
					  <tr>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Flight Number
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Scheduled Time
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  PickUp From
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Destination
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Tarrif/Fare 
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Customer Name
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Status
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Date
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						>
						  Options
						</th>
						<th
						  className={
							"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
							(color === "light"
							  ? "bg-gray-100 text-gray-600 border-gray-200"
							  : "bg-blue-800 text-blue-300 border-blue-700")
						  }
						></th>
					  </tr>
					</thead>
					<tbody> 
					{renderItems}
					</tbody>
				  </table>
				</div>
			  </div>
			</div>
		  </div>
		</>
	  );
	}
}


export default FlightPlan;
