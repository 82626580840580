import React from "react";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
// components

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

// Images Gal
import ddn_mars from '../assets/img/ddn-mars.gif';
import landing from '../assets/img/landing.jpg';
import vtol_city from '../assets/img/vtol-city.gif';


export default function Investors() {
  return (
    <>
      <IndexNavbar fixed />
	   <MetaTags>
            <title>Drone Business Investment Plan</title>
            <meta name="description" content="Martianis have designed revenue program for Aircraft Owners & play role as middle man or agency to manage all commecrial aspects such as Maintenance, Pilots & other Port services~" />
            <meta name="keywords" content="drone pilot jobs, drone pilot careers, drone pilot jobs in punjab" />
            <meta property="og:title" content="Drone Business Investment" />
            <meta property="og:image" content="../assets/img/component-profile-card.png" />
      </MetaTags>
      <main>
         <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                'url(' + ddn_mars + ')',
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                   Investor Relations - Smart Investment Program (SIP)
                  </h1>
                  <p className="mt-4 text-lg text-gray-300">
					Martianis offering Robust investment plan for Indian & Foriegn Nationals~
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
          

            <div className="flex flex-wrap items-center mt-32">
            

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-600">
                  <img
                    alt="drone delivery network mars"
                    src={landing}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-blue-600 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      Aircraft Management
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
						Our aircraft management service is tailored for the unique requirements of the individual aircraft Owner. The average managed aircraft generates over INR 20-25 Lakh per year of charter revenue for its ownership of single aircraft.
						<br/>Employing a layered approach to service, the Owner experience begins with an assigned Martianis Client Executive who gets the client onboard with the required documentation process. The Client Executive channels the expertise of our 24/7 Flight Operations Centre, dedicated Maintenance Desk and proprietary Customer Service Portal to keep the Owner up-to-date with his aircraft, while providing immediate answers to questions and flight requests. 
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                 src={vtol_city}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
              
                <h3 className="text-3xl font-semibold">Aircraft Maintenance
				</h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">       
					Martianis is a full-service maintenance provider dedicated to the rigorous upkeep of Owner aircraft. Highly trained engineers, aircraft technicians with extensively equipped facilities, dedicated Production Managers, an ample supply of spare parts, and a broad-spectrum warranty department of parts sold at MARS DronePort. 
					<br/>Allow Martianis to apply its exacting standards to your aircraft and enjoy complete confidence. Aircraft owners get maintenance facilities ready to go for each flight and dedicated staff for it!
                  </p>
                 
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
             
			 <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                FBO Operated by Former Toronto Pearson Intl Airport Employee
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
               Martianis’s 12,00 FT2 hangar located at the MARS DronePort Jalandhar is a modern and bright facility ideally situated to serve as an alternative to busy air traffic in the city.  Equipped with a luxurious restaurant for refreshment, crew amenities and a lot more can be found inside Fixed Based Operation.
				<br/>Martianis also offers a Custom space for Drones, on-site at the hangar for UAE’s. This DGCA approved facility is licensed for short-term storage of LiPO batteries etc. Other services include the handling and warehousing of inbound cargo on a 24/7 basis.

                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Excellent Services
                </h6>
                <p className="mt-2 mb-4 text-gray-500">
                  Poineer in Aviation
                </p>
              </div>
              
            </div>
          </div>
        </section>
       
      </main>
      <Footer />
    </>
  );
}
