import React, { createContext, Component, useState } from "react";
import { axiosInstance } from '../axiosInstance';
import qs from 'qs';
const MyContext = React.createContext();


class MyContextProvider extends Component{
    constructor(){
        super();
        this.isLoggedIn();
    }

    // Root State
    state = {
        showLogin:true,
        isAuth:false,
    }
    
    // Toggle between Login & Signup page
    toggleNav = () => {
        const showLogin = !this.state.showLogin;
        this.setState({
            ...this.state,
            showLogin
        })
    }

    // On Click the Log out button
    logoutUser = () => {
        localStorage.removeItem('loginToken');
        this.setState({
            ...this.state,
            isAuth:false
        })
    }

    registerUser = async (user) => {

        // Sending the user registration request
        const register = await axiosInstance.post('register.php',{
            name:user.name,
            email:user.email,
            password:user.password 
        });

        return register.data;
    }


    loginUser = async (user) => {

       var postData = {
		  login:user.email,
          password:user.password
		};

        // Sending the user Login request
        const login = await axiosInstance.post('user/login', qs.stringify(postData));
       // console.log(login);
        return login.data;
        
    }

    // Checking user logged in or not
    isLoggedIn = async () => {
		 const loginToken = localStorage.getItem('loginToken');
       //console.log(loginToken);
        // If inside the local-storage has the JWT token
        if(loginToken){

		// Fetching the user information
            const {data} = await axiosInstance.get('user/user');

            // If user information is successfully received
            if(data.success && data.user){
                this.setState({
                    ...this.state,
                    isAuth:true,
                });
            }

        }
    }

    render(){
        const contextValue = {
            rootState:this.state,
            toggleNav:this.toggleNav,
            isLoggedIn:this.isLoggedIn,
            registerUser:this.registerUser,
            loginUser:this.loginUser,
            logoutUser:this.logoutUser
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export { MyContext };

export default MyContextProvider;
