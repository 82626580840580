import React from "react";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

// components

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

// Images Gal
import ddn_mars from '../assets/img/ddn-mars.gif';
import landing from '../assets/img/landing.jpg';
import vtol_city from '../assets/img/vtol-city.gif';


export default function Safety() {
  return (
    <>
      <IndexNavbar fixed />
	   <MetaTags>
            <title>Drone Flight Safety Procedures</title>
            <meta name="description" content="Martianis has a robust Safety Management System (SMS) that few others do and exceptional services in the North Indian Region, Punjab! The SMS is designed & engineered for assessment~" />
            <meta name="keywords" content="drone flight safety, flight safety manual, drone pilot safety training" />
            <meta property="og:title" content="Drone Flight Safety Manual" />
            <meta property="og:image" content="../assets/img/component-profile-card.png" />
      </MetaTags>
      <main>
         <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                'url(' + ddn_mars + ')',
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                   Safety Practice
                  </h1>
                  <p className="mt-4 text-lg text-gray-300">
					Number One Priority prior to and after flight is Safety~
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas   fa-fighter-jet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Safety Management System (SMS)</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Martianis has a robust Safety Management System (SMS) that few others do and exceptional services in the North Indian Region, Punjab! The SMS is designed & engineered for assessment and management of both planned changes in mission plan enroute and/or quality escape by standardizing zones embedded in city traffic. 
					 
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Internal Evaluation Program</h6>
                    <p className="mt-2 mb-4 text-gray-600"> 
					- Scheduled Flights <br/> - Long Haul Flight operations data analysis enroute <br/> - Conducting safety risk assessments so Pilots understand their role and how to complete one successful flight <br/> - Monthly safety audits of Base safety by the safety manager <br/> - Base facility security reviews and upgrade
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Core Values</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Martianis was built on the belief that Unmanned aircrafts also known as Drones management solutions should be reliable, honest, transparent and safe. Each member of the Martianis Team is encouraged to initiate change that will bring about continuous improvements that enhance these core values. Aircraft owners and clients alike have come to rely upon Martianis commitment to these values. 
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fa fa-plane text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                 Crew Resource Management (CRM)
                </h3> 
				<p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
				In the mid 2020 prior release of Martianis, we began sending our pilots through an industry-leading Crew Resource Management (CRM) training program to nurture better communication between our pilots with the goal of creating a safer flight environment on martianis platform. The training includes Human Factors training, Communication Skills training, and Situational Awareness training and much.
				</p>
				<h5 className="text-3xl mb-2 font-semibold leading-normal">
				 Made by Martianis Engineers~</h5>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                Since 2007, we have been developing top leading software programs for Toronto Based companies that helped us to take it a step further by developing our own in-house CRM training program for UAV Pilots. And yes because we believe so strongly in the platform, we now even require our flight coordinators, client service representatives, UAVs maintenance technicians, and line service personnel to attend CRM classes annually as they are an extension of the crew, on the ground station established by Martianis so called MARS DronePort.
                </p>
                
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-600">
                  <img
                    alt="drone delivery network mars"
                    src={landing}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-blue-600 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      Featured Services
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                      At a Glance with state of art technolohy brought far over from seven seas~
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                 src={vtol_city}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-blue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blue-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">Emergency Response Program (ERP)
</h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                   
					All Pilots, Airworthiness must maintain a safety culture that should prevent safety incidents not only flight enroute but also includes factors that come in flight planning, we are committed to company-wide preparation in case of an event on flight enroute or ground operations. 
 
				We train all team members to respond to incidents and emergencies

                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            We train with our Base and advance ERPs
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                           Our team associated with an industry leader in response for drills and active events
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Security monitoring onboard aircraft with live observations at ground station provides advanced features to Aircraft Pilot to maneuver aircraft according to the safety measures enroute and land safely with Zero Tolerance on human injuries facts.  </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
             
			 <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Flight Operations Support Centre
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                  Our Flight Operations Support Centre is staffed 24/7 by a team of highly trained Dispatch personnel former employees of Toronto Pearson International Airport. These staff members coordinate the requirements of ongoing/upcoming trips, monitor the progress of flights, and coordinate external parties to ensure all the destinations fly operations are fully prepared for the arrival of our aircraft and clients.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Excellent Services
                </h6>
                <p className="mt-2 mb-4 text-gray-500">
                  Aviation demands Zero Tolerance!
                </p>
              </div>
              
            </div>
          </div>
        </section>
       
      </main>
      <Footer />
    </>
  );
}
