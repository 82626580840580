import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
//import Auth from "layouts/Auth.js";
import App from './App';
import * as serviceWorker from './serviceWorker';
// views without layouts

import Logout from "views/auth/Logout.js";
import Landing from "views/Landing.js";
//vini
import Howitworks from "views/Howitworks.js";
import Investors from "views/Investors.js";
import Safety from "views/Safety.js";
import Privacy from "views/Privacy.js";
import Contact from "views/Contact.js";
import Meet from "views/Meet.js";
import Fleet from "views/Fleet.js";
import News from "views/News.js";

import NotFound from "views/404.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={App} />
      <Route path="/logout" component={Logout} />
      {/* add routes without layouts */}
      <Route path="/landing" exact component={Landing} />
      <Route path="/howitworks" exact component={Howitworks} />
      <Route path="/privacy" exact component={Privacy} />
      <Route path="/investors" exact component={Investors} />
      <Route path="/safety" exact component={Safety} />
      <Route path="/fleet" exact component={Fleet} />
      <Route path="/meet" exact component={Meet} />
      <Route path="/news" exact component={News} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/" exact component={Index} />
	  <Route path="/404.error" component={NotFound} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/404.error" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);


serviceWorker.unregister();
