import React, { useContext }  from "react";
import { Switch, Redirect } from "react-router-dom";
import { MyContext } from "../contexts/MyContext";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

function Auth() {
  const {rootState} = useContext(MyContext);
  const {isAuth,showLogin} = rootState;
  //const { loginToken } = useToken();
    // If user Logged in
    if(isAuth)
    {
        return(
            <Switch>
				<Redirect from="/auth" to="/admin/dashboard" />
			</Switch>
        )
    }
    // Showing Login Or Register Page According to the condition
    else if(showLogin){
        return <Login/>;
    }
    else{
        return <Register/>;
    }
}

export default Auth;
