/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
/*IMAGES*/
import pattern_react from '../assets/img/pattern_react.png';
import ad_mi_plan from '../assets/img/ad-mi-plan.png';
import component_profile_card from '../assets/img/component-profile-card.png';
import component_btn from '../assets/img/component-btn.png';
import component_info_card from '../assets/img/component-info-card.png';
import component_info_2 from '../assets/img/component-info-2.png';
import component_menu from '../assets/img/component-menu.png';
import component_btn_pink from '../assets/img/component-btn-pink.png';
import login from '../assets/img/login.jpg';
import documentation from '../assets/img/documentation.png';
import profile from '../assets/img/profile.jpg';
import landing from '../assets/img/landing.jpg';

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
     

      <section className="mt-48 md:mt-40 pb-40 relative bg-gray-200">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4 pb-32 pt-48">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-plane text-xl"></i>
                </div>
                <h3 className="text-3xl font-semibold">
                    A Full Suite of Services
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                  At Martianis, our aim is establish Made in India brand since we are developing from scrath here in India.
                </p>
                    <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 mr-3">
                          <i className="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Aircraft Management
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 mr-3">
                          <i className="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Aircraft Maintenance
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 mr-3">
                          <i className="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Dedicated Account Managers to Aircraft Owners
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-100 mr-3">
                          <i className="fa fa-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Air Traveller’s Cargo Transport Programs
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={documentation}
              />
            </div>
          </div>
        </div>

        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <h2 className="font-semibold text-4xl">Categories of Drone/Aircraft Fleet </h2>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-600">
              At Martianis, we have devided aircraft category for delivery services starting from 1 Kg Payload~
            </p>
          </div>
        </div>
      </section>

      <section className="pb-16 bg-gray-300 relative pt-32">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon> 
          </svg>
        </div>

        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
            <div className="w-full text-left lg:w-8/12">
              <p className="text-4xl text-center">
                <span role="img" aria-label="love">
                  
                </span>
              </p>
             
              <p className="text-gray-600 text-lg leading-relaxed mt-4 mb-4">
               We have designed business plan and prototype where Indian Citizens and some foreign countries* citizens can invest not only for money but by Investing with Martianis, we are creating opportunities for youth to play essential roles in coming up years. <br/> <br/> <strong>Martianis Drone-port </strong>will also be known as Airport that will not only serve a city, we will be expanding horizons towards other cities of India~
              </p>
              <div className="sm:block flex flex-col mt-10">
                <a
                  href="#"
                  target="_blank"
                  className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gray-800 active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg"
                >
                  <i className="fa fa-plane text-lg mr-1"></i>
                  <span> Contact</span>
                </a>
              </div>
              <div className="text-center mt-16"></div>
            </div>
          </div>
        </div>
      </section>
	  
	  
      <section className="block relative z-1 bg-gray-700">
        <div className="container mx-auto">
          <div className="justify-center flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4  -mt-24">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    GunaKaari
                  </h5>
                  <a
                  href="https://do.martianis.com/web/site/fleet"
                  className="text-gray-400"
					>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="drone delivery network gunakaari"
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={login}
                      />
                    </div>
                  </a>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    BhagyaShaali
                  </h5>
                  <a
                  href="https://do.martianis.com/web/site/fleet"
                  className="text-gray-400"
					>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="drone delivery network bhagyashaali"
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={profile}
                      />
                    </div>
                  </a>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    ShaktiShaali
                  </h5>
                  <a
                  href="https://do.martianis.com/web/site/fleet"
                  className="text-gray-400"
					>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="drone delivery network shaktishaali"
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={landing}
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />
    </>
  );
}
