import React from "react";
import { Switch, Redirect } from "react-router-dom";



function Logout() {
	 const loginToken = localStorage.removeItem('loginToken');
  
	  if(!loginToken) {
		return(
            <Switch>
				<Redirect to="/auth/login?success=logout" />
			</Switch>
        )
	  }
}
export default Logout;
